.is-active {
    @apply font-bold border-b-2 border-indigo-400 text-indigo-400;
}

.required {
    @apply inline-block w-2 h-2 mr-2 bg-red-600 rounded-full;
}

.badge {
    @apply relative -right-0.5 -top-8 w-4 h-2 pl-1 pr-1 ml-2 mt-0 rounded-full bg-red-600 text-white font-mono text-xs leading-tight text-center;
}

.badge-no-text {
    @apply block relative -right-0.5 -top-5 w-2 h-2 pl-1 pr-1 ml-2 mt-0 rounded-full bg-green-300;
}

.badge-cleared {
    @apply block relative -right-0.5 -top-5 w-2 h-2 pl-1 pr-1 ml-2 mt-0 rounded-full bg-transparent;
}

.tab-line {
    height: 1px;
    bottom: 1px;
    transition: left 0.5s ease-in-out, width 0.5s 0.10s;
    pointer-events: none;
}